$mp: !important;
$mod_route:'';// '/core';
$site_path:'#{$mod_route}/icomoon';// public version

@font-face {
  font-family: 'icomoon';
  src:  url('#{$site_path}/fonts/icomoon.eot?355iv0');
  src:  url('#{$site_path}/fonts/icomoon.eot?355iv0#iefix') format('embedded-opentype'),
    url('#{$site_path}/fonts/icomoon.ttf?355iv0') format('truetype'),
    url('#{$site_path}/fonts/icomoon.woff?355iv0') format('woff'),
    url('#{$site_path}/fonts/icomoon.svg?355iv0#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}/*Learn To Build An SVG Animation With CSS*/

// DOCS: GOTCHA: remember to compile the scss file (koala)


.icon-refresh:before {
  content: "\e960";
}
.icon-refresh2:before {
  content: "\e961";
}
.icon-video2-off:before {
  content: "\e95a";
}
.icon-video2-on:before {
  content: "\e95b";
}
.icon-mic2-off:before {
  content: "\e95c";
}
.icon-mic2-on:before {
  content: "\e95d";
}
.icon-gaming:before {
  content: "\e959";
}
.icon-video-on:before {
  content: "\e954";
}
.icon-video-off:before {
  content: "\e956";
}
.icon-mic-on:before {
  content: "\e957";
}
.icon-mic-off:before {
  content: "\e958";
}
.icon-ideas:before {
  content: "\e94f";
}
.icon-classroom:before {
  content: "\e94d";
}
.icon-walk:before {
  content: "\e943";
}
.icon-bell2:before {
  content: "\e940";
}
.icon-users-add:before {
  content: "\e93f";
}
.icon-flame-icon:before {
  content: "\e93e";
}
.icon-star-1:before {
  content: "\e934";
}
.icon-star-2:before {
  content: "\e936";
}
.icon-star-3:before {
  content: "\e939";
}
.icon-star-4:before {
  content: "\e93c";
}
.icon-star-5:before {
  content: "\e93d";
}
.icon-send:before {
  content: "\e933";
}
.icon-check-all2:before {
  content: "\e918";
}
.icon-check-none2:before {
  content: "\e919";
}
.icon-check-none:before {
  content: "\e917";
}
.icon-check-all:before {
  content: "\e916";
}
.icon-hold:before {
  content: "\e915";
}
.icon-attach-out:before {
  content: "\e913";
}
.icon-attach-in:before {
  content: "\e912";
}
.icon-file:before {
  content: "\e904";
}
.icon-trophy2:before {
  content: "\e908";
}
.icon-trophy1:before {
  content: "\e909";
}
.icon-doing:before {
  content: "\e90a";
}
.icon-ribbon3:before {
  content: "\e90b";
}
.icon-ribbon2:before {
  content: "\e90c";
}
.icon-ribbon:before {
  content: "\e910";
}
.icon-move:before {
  content: "\e911";
}
.icon-options:before {
  content: "\e903";
}
.icon-repeat:before {
  content: "\e900";
}
.icon-portal:before {
  content: "\e901";
}
.icon-bookmark2:before {
  content: "\e902";
}
.icon-home:before {
  content: "\e905";
}
.icon-home3:before {
  content: "\e906";
}
.icon-office:before {
  content: "\e91a";
}
.icon-newspaper:before {
  content: "\e91d";
}
.icon-pencil:before {
  content: "\e907";
}
.icon-paint-format:before {
  content: "\e92a";
}
.icon-image:before {
  content: "\e90d";
}
.icon-images:before {
  content: "\e90e";
}
.icon-camera:before {
  content: "\e90f";
}
.icon-headphones:before {
  content: "\e91e";
}
.icon-music:before {
  content: "\e921";
}
.icon-play:before {
  content: "\e922";
}
.icon-film:before {
  content: "\e923";
}
.icon-video:before {
  content: "\e914";
}
.icon-dice:before {
  content: "\e925";
}
.icon-spades:before {
  content: "\e927";
}
.icon-clubs:before {
  content: "\e928";
}
.icon-connection:before {
  content: "\e91b";
}
.icon-podcast:before {
  content: "\e91c";
}
.icon-book:before {
  content: "\e91f";
}
.icon-books:before {
  content: "\e920";
}
.icon-library:before {
  content: "\e929";
}
.icon-file-text:before {
  content: "\e92b";
}
.icon-profile:before {
  content: "\e92e";
}
.icon-file-empty:before {
  content: "\e924";
}
.icon-files-empty:before {
  content: "\e931";
}
.icon-file-text2:before {
  content: "\e926";
}
.icon-copy:before {
  content: "\e92c";
}
.icon-paste:before {
  content: "\e92d";
}
.icon-stack:before {
  content: "\e932";
}
.icon-folder:before {
  content: "\e92f";
}
.icon-folder-open:before {
  content: "\e930";
}
.icon-price-tag:before {
  content: "\e935";
}
.icon-barcode:before {
  content: "\e937";
}
.icon-qrcode:before {
  content: "\e938";
}
.icon-cart:before {
  content: "\e93a";
}
.icon-coin-dollar:before {
  content: "\e93b";
}
.icon-lifebuoy:before {
  content: "\e941";
}
.icon-phone:before {
  content: "\e942";
}
.icon-address-book:before {
  content: "\e944";
}
.icon-envelope:before {
  content: "\e945";
}
.icon-pushpin:before {
  content: "\e946";
}
.icon-location:before {
  content: "\e947";
}
.icon-location2:before {
  content: "\e948";
}
.icon-compass:before {
  content: "\e949";
}
.icon-compass2:before {
  content: "\e94a";
}
.icon-map:before {
  content: "\e94b";
}
.icon-map2:before {
  content: "\e94c";
}
.icon-clock:before {
  content: "\e94e";
}
.icon-alarm:before {
  content: "\e950";
}
.icon-bell:before {
  content: "\e951";
}
.icon-stopwatch:before {
  content: "\e952";
}
.icon-calendar:before {
  content: "\e953";
}
.icon-keyboard:before {
  content: "\e955";
}
.icon-floppy-disk:before {
  content: "\e962";
}
.icon-database:before {
  content: "\e964";
}
.icon-undo2:before {
  content: "\e967";
}
.icon-redo2:before {
  content: "\e968";
}
.icon-forward:before {
  content: "\e969";
}
.icon-reply:before {
  content: "\e96a";
}
.icon-bubble:before {
  content: "\e96b";
}
.icon-bubbles2:before {
  content: "\e96d";
}
.icon-bubble2:before {
  content: "\e96e";
}
.icon-user:before {
  content: "\e971";
}
.icon-users:before {
  content: "\e972";
}
.icon-user-plus:before {
  content: "\e973";
}
.icon-user-minus:before {
  content: "\e974";
}
.icon-user-check:before {
  content: "\e975";
}
.icon-user-tie:before {
  content: "\e976";
}
.icon-quotes-left:before {
  content: "\e977";
}
.icon-quotes-right:before {
  content: "\e978";
}
.icon-spinner11:before {
  content: "\e984";
}
.icon-search:before {
  content: "\e986";
}
.icon-zoom-in:before {
  content: "\e987";
}
.icon-zoom-out:before {
  content: "\e988";
}
.icon-enlarge:before {
  content: "\e989";
}
.icon-key:before {
  content: "\e98d";
}
.icon-key2:before {
  content: "\e98e";
}
.icon-lock:before {
  content: "\e98f";
}
.icon-unlocked:before {
  content: "\e990";
}
.icon-wrench:before {
  content: "\e991";
}
.icon-cog:before {
  content: "\e994";
}
.icon-cogs:before {
  content: "\e995";
}
.icon-bug:before {
  content: "\e999";
}
.icon-stats-dots:before {
  content: "\e99b";
}
.icon-stats-bars:before {
  content: "\e99c";
}
.icon-trophy:before {
  content: "\e99e";
}
.icon-gift:before {
  content: "\e99f";
}
.icon-glass2:before {
  content: "\e9a1";
}
.icon-mug:before {
  content: "\e9a2";
}
.icon-spoon-knife:before {
  content: "\e9a3";
}
.icon-rocket:before {
  content: "\e9a5";
}
.icon-hammer2:before {
  content: "\e9a8";
}
.icon-fire:before {
  content: "\e9a9";
}
.icon-bin:before {
  content: "\e9ac";
}
.icon-bin2:before {
  content: "\e9ad";
}
.icon-briefcase:before {
  content: "\e9ae";
}
.icon-truck:before {
  content: "\e9b0";
}
.icon-target:before {
  content: "\e9b3";
}
.icon-power:before {
  content: "\e9b5";
}
.icon-switch:before {
  content: "\e9b6";
}
.icon-clipboard:before {
  content: "\e9b8";
}
.icon-list-numbered:before {
  content: "\e9b9";
}
.icon-list:before {
  content: "\e9ba";
}
.icon-list2:before {
  content: "\e9bb";
}
.icon-menu:before {
  content: "\e9bd";
}
.icon-menu3:before {
  content: "\e9bf";
}
.icon-menu4:before {
  content: "\e9c0";
}
.icon-cloud:before {
  content: "\e9c1";
}
.icon-cloud-download:before {
  content: "\e9c2";
}
.icon-cloud-upload:before {
  content: "\e9c3";
}
.icon-cloud-check:before {
  content: "\e9c4";
}
.icon-download3:before {
  content: "\e9c7";
}
.icon-upload3:before {
  content: "\e9c8";
}
.icon-sphere:before {
  content: "\e9c9";
}
.icon-earth:before {
  content: "\e9ca";
}
.icon-link:before {
  content: "\e9cb";
}
.icon-flag:before {
  content: "\e9cc";
}
.icon-attachment:before {
  content: "\e9cd";
}
.icon-eye:before {
  content: "\e9ce";
}
.icon-eye-plus:before {
  content: "\e9cf";
}
.icon-eye-minus:before {
  content: "\e9d0";
}
.icon-eye-blocked:before {
  content: "\e9d1";
}
.icon-bookmark:before {
  content: "\e9d2";
}
.icon-bookmarks:before {
  content: "\e9d3";
}
.icon-contrast:before {
  content: "\e9d5";
}
.icon-star-empty:before {
  content: "\e9d7";
}
.icon-star-half:before {
  content: "\e9d8";
}
.icon-star-full:before {
  content: "\e9d9";
}
.icon-heart:before {
  content: "\e9da";
}
.icon-heart-broken:before {
  content: "\e9db";
}
.icon-warning:before {
  content: "\ea07";
}
.icon-notification:before {
  content: "\ea08";
}
.icon-question:before {
  content: "\ea09";
}
.icon-plus:before {
  content: "\ea0a";
}
.icon-minus:before {
  content: "\ea0b";
}
.icon-info:before {
  content: "\ea0c";
}
.icon-cancel-circle:before {
  content: "\ea0d";
}
.icon-blocked:before {
  content: "\ea0e";
}
.icon-cross:before {
  content: "\ea0f";
}
.icon-checkmark:before {
  content: "\ea10";
}
.icon-checkmark2:before {
  content: "\ea11";
}
.icon-spell-check:before {
  content: "\ea12";
}
.icon-enter:before {
  content: "\ea13";
}
.icon-exit:before {
  content: "\ea14";
}
.icon-arrow-up:before {
  content: "\ea32";
}
.icon-arrow-right:before {
  content: "\ea34";
}
.icon-arrow-down:before {
  content: "\ea36";
}
.icon-arrow-left:before {
  content: "\ea38";
}
.icon-circle-up:before {
  content: "\ea41";
}
.icon-circle-right:before {
  content: "\ea42";
}
.icon-circle-down:before {
  content: "\ea43";
}
.icon-circle-left:before {
  content: "\ea44";
}
.icon-sort-alpha-asc:before {
  content: "\ea48";
}
.icon-sort-alpha-desc:before {
  content: "\ea49";
}
.icon-sort-numeric-asc:before {
  content: "\ea4a";
}
.icon-sort-numeric-desc:before {
  content: "\ea4b";
}
.icon-checkbox-checked:before {
  content: "\ea52";
}
.icon-checkbox-unchecked:before {
  content: "\ea53";
}
.icon-radio-checked:before {
  content: "\ea54";
}
.icon-radio-checked2:before {
  content: "\ea55";
}
.icon-radio-unchecked:before {
  content: "\ea56";
}
.icon-font-size:before {
  content: "\ea61";
}
.icon-share:before {
  content: "\ea7d";
}
.icon-embed:before {
  content: "\ea7f";
}
.icon-embed2:before {
  content: "\ea80";
}
.icon-terminal:before {
  content: "\ea81";
}
.icon-share2:before {
  content: "\ea82";
}
.icon-mail2:before {
  content: "\ea84";
}
.icon-amazon:before {
  content: "\ea87";
}
.icon-google:before {
  content: "\ea88";
}
.icon-google2:before {
  content: "\ea89";
}
.icon-google3:before {
  content: "\ea8a";
}
.icon-hangouts:before {
  content: "\ea8e";
}
.icon-google-drive:before {
  content: "\ea8f";
}
.icon-facebook:before {
  content: "\ea90";
}
.icon-facebook2:before {
  content: "\ea91";
}
.icon-instagram:before {
  content: "\ea92";
}
.icon-whatsapp:before {
  content: "\ea93";
}
.icon-spotify:before {
  content: "\ea94";
}
.icon-twitter:before {
  content: "\ea96";
}
.icon-rss:before {
  content: "\ea9b";
}
.icon-rss2:before {
  content: "\ea9c";
}
.icon-youtube:before {
  content: "\ea9d";
}
.icon-twitch:before {
  content: "\ea9f";
}
.icon-vimeo:before {
  content: "\eaa0";
}
.icon-lanyrd:before {
  content: "\eaa2";
}
.icon-dribbble:before {
  content: "\eaa7";
}
.icon-behance:before {
  content: "\eaa8";
}
.icon-behance2:before {
  content: "\eaa9";
}
.icon-steam:before {
  content: "\eaac";
}
.icon-steam2:before {
  content: "\eaad";
}
.icon-dropbox:before {
  content: "\eaae";
}
.icon-github:before {
  content: "\eab0";
}
.icon-npm:before {
  content: "\eab1";
}
.icon-wordpress:before {
  content: "\eab4";
}
.icon-joomla:before {
  content: "\eab5";
}
.icon-blogger:before {
  content: "\eab7";
}
.icon-blogger2:before {
  content: "\eab8";
}
.icon-tux:before {
  content: "\eabd";
}
.icon-appleinc:before {
  content: "\eabe";
}
.icon-finder:before {
  content: "\eabf";
}
.icon-android:before {
  content: "\eac0";
}
.icon-windows:before {
  content: "\eac1";
}
.icon-windows8:before {
  content: "\eac2";
}
.icon-skype:before {
  content: "\eac5";
}
.icon-reddit:before {
  content: "\eac6";
}
.icon-wikipedia:before {
  content: "\eac8";
}
.icon-linkedin:before {
  content: "\eac9";
}
.icon-linkedin2:before {
  content: "\eaca";
}
.icon-stumbleupon:before {
  content: "\eace";
}
.icon-stumbleupon2:before {
  content: "\eacf";
}
.icon-stackoverflow:before {
  content: "\ead0";
}
.icon-pinterest:before {
  content: "\ead1";
}
.icon-pinterest2:before {
  content: "\ead2";
}
.icon-paypal:before {
  content: "\ead8";
}
.icon-chrome:before {
  content: "\ead9";
}
.icon-firefox:before {
  content: "\eada";
}
.icon-safari:before {
  content: "\eadd";
}
.icon-phone1:before {
  content: "\e95e";
}
.icon-phone-hang-up:before {
  content: "\e95f";
}
