$mp: !important;
// in bottom of  arc_site.scss
//@import "flicker";

.heartbeat {
	-webkit-animation: heartbeat 1.5s ease-in-out infinite both;
	        animation: heartbeat 1.5s ease-in-out infinite both;
}

/* ----------------------------------------------
 * Generated by Animista on 2020-6-23 18:5:49
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation heartbeat
 * ----------------------------------------
 */
@-webkit-keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}
@keyframes heartbeat {
  from {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-transform-origin: center center;
            transform-origin: center center;
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  10% {
    -webkit-transform: scale(0.91);
            transform: scale(0.91);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  17% {
    -webkit-transform: scale(0.98);
            transform: scale(0.98);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
  33% {
    -webkit-transform: scale(0.87);
            transform: scale(0.87);
    -webkit-animation-timing-function: ease-in;
            animation-timing-function: ease-in;
  }
  45% {
    -webkit-transform: scale(1);
            transform: scale(1);
    -webkit-animation-timing-function: ease-out;
            animation-timing-function: ease-out;
  }
}

.shonuff{
  -webkit-animation: shonuff 1.5s ease-in-out infinite both $mp;
  animation: shonuff 1.5s ease-in-out infinite both $mp; 
  transform: scale(.9) $mp;/*slightly smaller, glow sometimes appears cut off*/
}

@keyframes shonuff {
  from {
    box-shadow: 0px 0px 0px 0px #0ff; }
    to {
      box-shadow: 0px 0px 14px 7px #0ff;
    }
    /* [css keyframes](https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes) */
}

@-webkit-keyframes shonuff {
  from {
    box-shadow: 0px 0px 0px 0px #0ff; }
    to {
      box-shadow: 0px 0px 14px 7px #0ff;
    }
    /* [css keyframes](https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes) */
}

.showoff{
  -webkit-animation: showoff 1.5s ease-in-out infinite both $mp;
  animation: showoff 1.5s ease-in-out infinite both $mp; 
  transform: scale(.9) $mp;/*slightly smaller, glow sometimes appears cut off*/
}

@keyframes showoff {
  from {
    text-shadow: 0px 0px 0px #0ff; }
    to {
      text-shadow: 0px 0px 14px #0ff;
    }
    /* [css keyframes](https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes) */
}

@-webkit-keyframes showoff {
  from {
    text-shadow: 0px 0px 0px 0px #0ff; }
    to {
      text-shadow: 0px 0px 14px 7px #0ff;
    }
    /* [css keyframes](https://developer.mozilla.org/en-US/docs/Web/CSS/@keyframes) */
}