@use "../../scss/media";

.acc-content{
  .content {
    p:last-of-type {
      margin-bottom: 0;
      // color: red;
    }
  }
  .bullets{
    padding: 0 3rem 2rem;
    li{
      list-style: disc;
      list-style-position: inside;
      font-size: 16px;
      font-family: 'Didact Gothic', sans-serif;
      span{
        display: inline;
        position: relative;
        left: -0.5rem;
      }
    }
  }

  &.company{
    h4, h5{
      padding: 0 3rem 0.5rem;
      font-family: 'Oswald', sans-serif;
      font-weight: 300;
      // font-weight: bold;
    }
    ul li{
      list-style: unset;
      padding-bottom: 0.5rem;
      span{left: unset;}
    }
  }
}

.faq{
  .faq-title{
    display: flex;
    justify-content: space-between;
    // display: grid;
    //   grid-template-columns: 80% auto;
    .section-title{
      font-size: 2rem;
    }
    .faq-download{
      width: 4rem;
      font-size: 2rem;
      text-align: center;
      @include media.mobile {
        //border: 1px solid red;// WORKS
        font-size: 3rem;
      }
      p{
        font-size: 1.2rem;
        @include media.mobile { font-size: 2rem;}
      }
    }
    *{color: white;}
  }
}