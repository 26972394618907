$mp: !important;

:root {
  --main-color: #a58d70;
  --off-main-color: #f68864;
  --white: #fff;
  --grey: #9e9e9e;
  --main-bg: #272727;
}

.cancelBtn{
  background-image: linear-gradient(to bottom, #f9fff8, #AAADAA) !important;
}

.register_view_modal_cont {
  z-index: 10000 $mp;
}

.jng-mobile{padding: unset !important; margin:unset !important;}

.plax-bg{ background-color: var(--main-bg);}

// .section-padding{
//   padding: 90px 0 0 $mp;
//   padding-bottom: 90px;
// }
// .about{padding-bottom: unset $mp;}

// .ui-icon-download:before,.d3-icon-download:before {
//   background-image: url(data:image/svg+xml;base64,PCEtLSBHZW5lcmF0ZWQgYnkgSWNvTW9vbi5pbyAtLT4KPHN2ZyB2ZXJzaW9uPSIxLjEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjMyIiBoZWlnaHQ9IjMyIiB2aWV3Qm94PSIwIDAgMzIgMzIiPgo8dGl0bGU+Y2xvdWQtZG93bmxvYWQ8L3RpdGxlPgo8cGF0aCBkPSJNMjcuODQ0IDExLjI1MmMtMC4xMDEtNC4wMjItMy4zODktNy4yNTItNy40MzMtNy4yNTItMi4zNjkgMC00LjQ3NyAxLjEwOS01LjgzOSAyLjgzNS0wLjc2NC0wLjk4Ny0xLjk1OS0xLjYyNC0zLjMwMy0xLjYyNC0yLjMwNyAwLTQuMTc2IDEuODcxLTQuMTc2IDQuMTc5IDAgMC4yMDEgMC4wMTUgMC4zOTkgMC4wNDMgMC41OTItMC4zNTEtMC4wNjMtMC43MTEtMC4wOTgtMS4wODAtMC4wOTgtMy4zNDQtMC02LjA1NCAyLjcxMi02LjA1NCA2LjA1OHMyLjcxIDYuMDU4IDYuMDU0IDYuMDU4aDIuODY4bDcuMDc4IDcuMzI4IDcuMDc4LTcuMzI4IDMuNDg0LTBjMy4wMDQtMC4wMDYgNS40MzgtMi40NDQgNS40MzgtNS40NTEgMC0yLjU2NS0xLjc3MS00LjcxNi00LjE1Ni01LjI5NnpNMTYgMjZsLTYtNmg0di02aDR2Nmg0bC02IDZ6Ij48L3BhdGg+Cjwvc3ZnPgo=
//   );/* Make your icon fit */
//   background-size: contain;/*background-size: 18px 18px;*/
// }

@media(max-width: 650px){
      .jng-mobile{display: none;}
}// @media

@import "./scss/flicker";
@import "./scss/_d3po";