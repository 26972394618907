$mp: !important;

.services{
  .item{
    height: 100%;
    img{
      height: 60px $mp;
      width: auto $mp;
    }
  }
}