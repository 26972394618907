$mp: !important;
@use "../../scss/media";

.testimonials{
  padding: unset $mp;
  .testimonial-wrapper{
    height: 70vh;
    padding: 90px 0;
    @include media.desktop{height: 80vh;}
    // padding: unset $mp;
    .vid-wrapper{
      position: absolute;
      top: 0;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
      height: 70vh;
      overflow: hidden;
      @include media.desktop{height: 80vh;}
      // @include media.XXL{height: 100%;}
      .testimonial-video{
        width: 120%;
        @include media.tablet{height: 100%; width: unset;}
      }
    }// vid-wrapper
    .video-credits{
      position: absolute; 
      bottom: -1.5rem;
      font-size: 0.7rem;
      left: 1rem;
      color: #b19777;
      z-index: 100;
    }
    .container{
      // top: 5rem;
    }// container
  }// testimonial-wrapper
  .item{
    margin: unset;
    .info{
      margin: unset;
    }
  }// item
  .owl-dots{margin-top: 1.5rem $mp;}
}

.testimonials.plax{
  .testimonial-wrapper{
    .vid-wrapper{
      width: 100%;
      .testimonial-video{
        position: fixed;
        top: 0;
        z-index: -1;
      }// testimonial-video
    }// vid-wrapper
  }// testimonial-wrapper
}