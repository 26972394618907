$mp: !important;

nav.navbar{
  .logo {
    width: unset;
    h2{
      font-size: 22px;/*35px;*/
      span{display: inline;}
      div{text-align: left;}
    }
  }// .logo
}// .navbar

.logo-wrapper{padding: 2rem;}// .logo-wrapper